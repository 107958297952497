import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import RsIcons from "./RsIcons";

export default function Footer() {
  // styles
  const FooterStyles = {
    backgroundColor: "#00aed2",
  };
  const fb = {
    border: "none",
    overflow: "hidden",
  };
  return (
    <>
      <footer style={FooterStyles}>
        <Container>
          <Row className="justify-content-center py-5 text-white text-center align-items-center">
            <Col lg={4} md={10} sm={12} className="py-3 py-lg-0">
              <h2 className="kcrg py-3" id="contactenos">
                Contáctanos
              </h2>
              <p className="no-link">
                Bogotá
                <br />
                <a
                  href="https://goo.gl/maps/uRJQUcQb7oTka23K6"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-reset text-decoration-none"
                >
                  Carrera 42 Bis #17A - 53
                </a>
                <br />
                <a
                  href="tel:6019156661"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-reset text-decoration-none"
                >
                  PBX: 9156661
                </a>
              </p>
              <p>
                Barranquilla
                <br />
                <a
                  href="https://goo.gl/maps/6RTcfcAtsySCkt9v6"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-reset text-decoration-none"
                >
                  Carrera 53 #61-109 Prado
                </a>
                <br />
                <a
                  href="tel:6019156661"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-reset text-decoration-none"
                >
                  PBX: 9156661
                </a>
              </p>
              <p>
                <a
                  href="/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-reset text-decoration-none"
                >
                  www.asisteing.com
                </a>
                <br />
                <a
                  href="mailto:contacto@asisteing.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-reset text-decoration-none"
                >
                  contacto@asisteing.com
                </a>
              </p>
              <RsIcons />
            </Col>
            <Col
              lg={4}
              md={10}
              sm={12}
              className="justify-content-center py-3 py-lg-0"
            >
              <h2 className="kcrg py-3">Últimas noticias</h2>
              <iframe
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fasisteingsas&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId=264460871939009"
                width="340"
                height="500"
                title="Facebook Asiste Ingeniería SAS"
                style={fb}
                scrolling="no"
                frameborder="0"
                allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              ></iframe>
            </Col>
            <Col lg={4} md={10} sm={12} className="py-3 py-lg-0">
              <img
                src={require("../images/LOGO-BLANCO.png")}
                placeholder="blurred"
                className=""
                height={"150"}
                alt="Logo Asiste Ingeniería"
              />
              <p>
                Todos los derechos reservados.
                <br />
                Asiste ING S.A.S.&trade;
                <br />
                2022
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}
