import * as React from "react";

import { Button, Col, Row, Carousel } from "react-bootstrap";
import VisibilitySensor from "react-visibility-sensor";
import CountUp from "react-countup";
import { Link } from "react-router-dom";

import "./Home.css";
import Layout from "../components/Layout";
import Video from "../components/Video";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Seo from "../components/Seo";

export default function Home() {
  //styles
  const firstBanner = {
    backgroundColor: "#00aed2",
  };
  
  const textRed = {
    color: "#00aed2",
  };

  const botonOfertas = {
    backgroundColor: "#1b4899",
    borderColor: "#1b4899",
    borderRadius: "1em",
    padding: "0.3em 1.2em",
    fontSize: "2em",
  };

  const textYellow = {
    color: "#1b4899",
  };

  const contador = {
    fontSize: "5em",
  };
  return (
    <>
      <Seo
        title="Asiste ING | Te estamos buscando | Tu talento cuenta"
        description="¡Únete! Somos una empresa de brazos abiertos, contamos con el mejor y más diverso talento humano que conforma un gran equipo en el servicio de Contact Center."
        url="https://asisteing.com"
      />
      <Header />
      <Layout>
        <Row>
          <Carousel className="p-0" id="SA500">
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={require("../images/Home/PRINCIPAL.jpg")}
                alt="First slide"
              />
            </Carousel.Item>
          </Carousel>
        </Row>
        <Row
          className="justify-content-center py-5 text-white"
          style={firstBanner}
        >
          <Col lg={4} md={6} sm={12}>
            <img
              className="img-fluid"
              src={require("../images/Home/IMG_2211.jpg")}
              alt=""
            />
          </Col>
          <Col lg={4} md={6} sm={12} className="py-3 py-md-0">
            <h2 className="kcrg">Somos Familia</h2>
            <p>
              Somos una empresa de brazos abiertos, contamos con el mejor y más
              diverso talento humano que conforma un gran equipo que busca
              soluciones para brindar un servicio ágil, asertivo, eficaz,
              comprometido y cuidadoso, atendiendo las necesidades y
              requerimientos de nuestros clientes.
            </p>
            <p>
              Prestamos el servicio de CONTACT CENTER a través de líneas de
              inbound y outbound, en las áreas de telemercadeo, soporte técnico
              y servicio al cliente.
            </p>
            <p>¡Juntos somos el mejor equipo!</p>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center my-5">
          <Col lg={4} md={6} sm={12} className="pb-3">
            <div>
              <h2 className="text-center kcrg" style={textRed}>
                Nuestras instalaciones
              </h2>
              <div className="d-flex">
                <img
                  className="img-fluid mx-auto"
                  src={require("../images/Home/UBICACION.png")}
                  alt="Ícono Ubicación"
                />
              </div>
              <h3 className="text-center kcrg" style={textYellow}>
                Bogotá
              </h3>
              <div className="d-flex">
                <img
                  className="img-fluid mx-auto"
                  src={require("../images/Home/UBICACION.png")}
                  alt="Ícono Ubicación"
                />
              </div>
              <h3 className="text-center kcrg" style={textYellow}>
                Barranquilla
              </h3>
            </div>
          </Col>
          <Col lg={5} md={6} sm={12} className="d-flex align-items-center">
            <Carousel className="p-0">
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={require("../images/Home/01.JPG")}
                  alt="First slide"
                />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
        <Row
          className="justify-content-center py-5"
          style={firstBanner}
          id="UTFA"
        >
          <Col
            lg={4}
            md={4}
            sm={12}
            className="justify-content-center text-center py-2"
          >
            <div className="d-flex">
              <img
                className="img-fluid mx-auto"
                src={require("../images/Home/HOJA.png")}
                alt=""
              />
            </div>
            <VisibilitySensor partialVisibility offset={{ bottom: 100 }}>
              {({ isVisible }) => (
                <div style={{ height: 100 }}>
                  {isVisible ? (
                    <CountUp
                      prefix="+"
                      end={128}
                      className="text-white kcrg"
                      style={contador}
                    />
                  ) : (
                    <p className="text-white kcrg" style={contador}>
                      +0
                    </p>
                  )}
                </div>
              )}
            </VisibilitySensor>
            <h3 className="kcrg" style={textYellow}>
              Vacantes Asiste ING
            </h3>
          </Col>
          <Col
            lg={4}
            md={4}
            sm={12}
            className="justify-content-center text-center py-2"
          >
            <div className="d-flex">
              <img
                className="img-fluid mx-auto"
                src={require("../images/Home/COLABORADORES.png")}
                alt=""
              />
            </div>
            <VisibilitySensor partialVisibility offset={{ bottom: 100 }}>
              {({ isVisible }) => (
                <div style={{ height: 100 }}>
                  {isVisible ? (
                    <CountUp
                      prefix="+"
                      end={1000}
                      className="text-white kcrg"
                      style={contador}
                    />
                  ) : (
                    <p className="text-white kcrg" style={contador}>
                      +0
                    </p>
                  )}
                </div>
              )}
            </VisibilitySensor>
            <h3 className="kcrg" style={textYellow}>
              Colaboradores en Asiste ING
            </h3>
          </Col>
          <Col
            lg={4}
            md={4}
            sm={12}
            className="justify-content-center text-center py-2"
          >
            <div className="d-flex">
              <img
                className="img-fluid mx-auto"
                src={require("../images/Home/CRECIMIENTO.png")}
                alt=""
              />
            </div>
            <VisibilitySensor partialVisibility offset={{ bottom: 100 }}>
              {({ isVisible }) => (
                <div style={{ height: 100 }}>
                  {isVisible ? (
                    <CountUp
                      prefix="+"
                      end={50}
                      className="text-white kcrg"
                      style={contador}
                    />
                  ) : (
                    <p className="text-white kcrg" style={contador}>
                      +0
                    </p>
                  )}
                </div>
              )}
            </VisibilitySensor>
            <h3 className="kcrg" style={textYellow}>
              Crecimiento profesional
            </h3>
          </Col>
        </Row>
        <Row className="justify-content-center my-5">
          <Col lg={4} md={6} sm={12}>
            <h2 className="kcrg" style={textRed}>
              Únete al #TeamFamiliaAsiste
            </h2>
            <p>
              En Asiste ING S.A.S. encontrarás un lugar para crecer
              profesional y laboralmente.
            </p>
            <p>
              Vence tus miedos, alcanza tus metas, y supera cualquier obstaculo
              de la mano de un equipo que se caracteriza por su alegria, calidad
              y que siempre está en constante aprendizaje.
            </p>
            <div className="text-center py-3">
              <Button
                as={Link}
                variant="primary"
                size="lg"
                style={botonOfertas}
                className="kcrg"
                to="/ReclutaFace"
              >
                Conoce nuestras ofertas
              </Button>
            </div>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <Video
              videoSrcURL="https://www.youtube.com/embed/e-wWvU3k808"
              videoTitle="Trabajar en Asiste"
            ></Video>
          </Col>
        </Row>
      </Layout>
      <Footer />
    </>
  );
}
