import React from "react";
import { Nav, Navbar, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import RsIcons from "./RsIcons";

export default function Header() {
  const NavbarStyles = {
    backgroundColor: "#00aed2",
  };

  return (
    <>
      <Navbar expand="md" sticky="top" style={NavbarStyles}>
        <Container>
          <Navbar.Brand as={NavLink} to="/">
            <img
              src={require("../images/LOGO-BLANCO-ASISTE.png")}
              width="160"
              height="50"
              className="d-inline-block align-top"
              alt="Logo Asiste Ingeniería"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="d-flex align-items-center justify-content-evenly w-100">
              <Nav.Link href="#SA500" className="text-white text-center">
                Somos
                <br />
                Familia
              </Nav.Link>
              <Nav.Link href="#UTFA" className="text-white text-center">
                Únete al
                <br />
                #TeamFamiliaAsiste
              </Nav.Link>
              <Nav.Link href="#contactenos" className="text-white text-center">
                Contáctanos
              </Nav.Link>
            </Nav>
            <Navbar.Text className="w-25">
              <RsIcons />
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
